import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dbe5a5be = () => interopDefault(import('../src/pages/foo.vue' /* webpackChunkName: "pages/foo" */))
const _e1c7fa8a = () => interopDefault(import('../src/pages/ie/index.js' /* webpackChunkName: "pages/ie/index" */))
const _1b878a0a = () => interopDefault(import('../src/pages/ie/ie.vue' /* webpackChunkName: "pages/ie/ie" */))
const _250f72e6 = () => interopDefault(import('../src/modules/admin/pages/index' /* webpackChunkName: "" */))
const _8a97a6ba = () => interopDefault(import('../src/modules/angels/pages/frontend/index' /* webpackChunkName: "" */))
const _1568de0d = () => interopDefault(import('../src/modules/angels/pages/frontend/thank-you' /* webpackChunkName: "" */))
const _58da570a = () => interopDefault(import('../src/modules/appeals/pages/frontend/show' /* webpackChunkName: "" */))
const _62cf9cdb = () => interopDefault(import('../src/modules/auth/pages/register' /* webpackChunkName: "" */))
const _fe08279e = () => interopDefault(import('../src/modules/auth/pages/login' /* webpackChunkName: "" */))
const _68bc5666 = () => interopDefault(import('../src/modules/auth/pages/forgot-password' /* webpackChunkName: "" */))
const _1648e4de = () => interopDefault(import('../src/modules/auth/pages/reset-password' /* webpackChunkName: "" */))
const _757383ef = () => interopDefault(import('../src/modules/basket/pages/frontend/index' /* webpackChunkName: "" */))
const _a92ec834 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/basket' /* webpackChunkName: "" */))
const _b2d0d834 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment' /* webpackChunkName: "" */))
const _403e235b = () => interopDefault(import('../src/modules/basket/pages/frontend/index/payment-details' /* webpackChunkName: "" */))
const _465f6d6e = () => interopDefault(import('../src/modules/basket/pages/frontend/index/login' /* webpackChunkName: "" */))
const _15fdd418 = () => interopDefault(import('../src/modules/basket/pages/frontend/index/receipt' /* webpackChunkName: "" */))
const _12d98707 = () => interopDefault(import('../src/modules/cms/pages/index' /* webpackChunkName: "" */))
const _2c99ecfe = () => interopDefault(import('../src/modules/cms/pages/news/index' /* webpackChunkName: "" */))
const _a6996ef8 = () => interopDefault(import('../src/modules/cms/pages/news/item' /* webpackChunkName: "" */))
const _76af3f4e = () => interopDefault(import('../src/modules/donations/pages/frontend/donate' /* webpackChunkName: "" */))
const _4a8fe110 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/index' /* webpackChunkName: "" */))
const _72458f16 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/create' /* webpackChunkName: "" */))
const _12efcab8 = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/edit' /* webpackChunkName: "" */))
const _183c7a8e = () => interopDefault(import('../src/modules/entrepreneurs/pages/admin/select-mfi' /* webpackChunkName: "" */))
const _0b2b2a0a = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-index' /* webpackChunkName: "" */))
const _d59ed5b0 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/due-mfi' /* webpackChunkName: "" */))
const _225921d8 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-index' /* webpackChunkName: "" */))
const _27762876 = () => interopDefault(import('../src/modules/grant-repayments/pages/admin/archive-mfi' /* webpackChunkName: "" */))
const _a3af780a = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/index' /* webpackChunkName: "" */))
const _68579298 = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/show' /* webpackChunkName: "" */))
const _66ef833d = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _24bbd5ab = () => interopDefault(import('../src/modules/grant-reporting/pages/admin/archive' /* webpackChunkName: "" */))
const _330bb8b3 = () => interopDefault(import('../src/modules/grants/pages/frontend/home' /* webpackChunkName: "" */))
const _9d98f708 = () => interopDefault(import('../src/modules/grants/pages/frontend/search' /* webpackChunkName: "" */))
const _33109ed1 = () => interopDefault(import('../src/modules/grants/pages/frontend/show' /* webpackChunkName: "" */))
const _7329dc6e = () => interopDefault(import('../src/modules/grants/pages/admin/index' /* webpackChunkName: "" */))
const _045608fe = () => interopDefault(import('../src/modules/grants/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _061a5236 = () => interopDefault(import('../src/modules/grants/pages/admin/create' /* webpackChunkName: "" */))
const _40330a33 = () => interopDefault(import('../src/modules/grants/pages/admin/edit' /* webpackChunkName: "" */))
const _ad161c40 = () => interopDefault(import('../src/modules/homepage/pages/index' /* webpackChunkName: "" */))
const _e0b3f118 = () => interopDefault(import('../src/modules/loans/pages/frontend/search' /* webpackChunkName: "" */))
const _c091006e = () => interopDefault(import('../src/modules/loans/pages/frontend/show' /* webpackChunkName: "" */))
const _42745e7e = () => interopDefault(import('../src/modules/loans/pages/admin/index' /* webpackChunkName: "" */))
const _561a2879 = () => interopDefault(import('../src/modules/loans/pages/admin/select-entrepreneur' /* webpackChunkName: "" */))
const _20201226 = () => interopDefault(import('../src/modules/loans/pages/admin/create' /* webpackChunkName: "" */))
const _72888c3b = () => interopDefault(import('../src/modules/loans/pages/admin/edit' /* webpackChunkName: "" */))
const _2bc19efd = () => interopDefault(import('../src/modules/loans/pages/admin/messages' /* webpackChunkName: "" */))
const _2017e436 = () => interopDefault(import('../src/modules/marketing/pages/frontend/shop' /* webpackChunkName: "" */))
const _72ec70b8 = () => interopDefault(import('../src/modules/marketing/pages/frontend/welcome-answer' /* webpackChunkName: "" */))
const _55e69af5 = () => interopDefault(import('../src/modules/mfis/pages/admin/index' /* webpackChunkName: "" */))
const _23cbab87 = () => interopDefault(import('../src/modules/mfis/pages/admin/edit' /* webpackChunkName: "" */))
const _58bd3941 = () => interopDefault(import('../src/modules/mfis/pages/admin/admins' /* webpackChunkName: "" */))
const _57c4ad58 = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/index' /* webpackChunkName: "" */))
const _1759d548 = () => interopDefault(import('../src/modules/mfis/pages/admin/mfi-info-messages/edit' /* webpackChunkName: "" */))
const _8e9f7d1a = () => interopDefault(import('../src/modules/offsetting/pages/admin/index' /* webpackChunkName: "" */))
const _a58b55ba = () => interopDefault(import('../src/modules/offsetting/pages/admin/archive' /* webpackChunkName: "" */))
const _ebcaab88 = () => interopDefault(import('../src/modules/offsetting/pages/admin/show' /* webpackChunkName: "" */))
const _29cf0cb5 = () => interopDefault(import('../src/modules/offsetting/pages/admin/no-current-report' /* webpackChunkName: "" */))
const _5d2da23d = () => interopDefault(import('../src/modules/referrals/pages/frontend/make-referral' /* webpackChunkName: "" */))
const _943a4548 = () => interopDefault(import('../src/modules/referrals/pages/frontend/referral' /* webpackChunkName: "" */))
const _309c8c12 = () => interopDefault(import('../src/modules/repayments/pages/admin/index' /* webpackChunkName: "" */))
const _498317fc = () => interopDefault(import('../src/modules/repayments/pages/admin/archive' /* webpackChunkName: "" */))
const _10ae8048 = () => interopDefault(import('../src/modules/reporting/pages/reporting' /* webpackChunkName: "" */))
const _027c13cc = () => interopDefault(import('../src/modules/reporting/pages/reporting/donations' /* webpackChunkName: "" */))
const _714388ec = () => interopDefault(import('../src/modules/reporting/pages/reporting/reconciliation' /* webpackChunkName: "" */))
const _6c509d28 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-changes' /* webpackChunkName: "" */))
const _985d5136 = () => interopDefault(import('../src/modules/reporting/pages/reporting/user-data' /* webpackChunkName: "" */))
const _ac6657de = () => interopDefault(import('../src/modules/reporting/pages/reporting/gift-aid-data' /* webpackChunkName: "" */))
const _c8d6ca38 = () => interopDefault(import('../src/modules/reporting/pages/reporting/entrepreneur-and-loan-data' /* webpackChunkName: "" */))
const _b04033b2 = () => interopDefault(import('../src/modules/site-settings/pages/index' /* webpackChunkName: "" */))
const _58dfaad0 = () => interopDefault(import('../src/modules/site-settings/pages/index/loan-activity-types' /* webpackChunkName: "" */))
const _68ef329e = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources' /* webpackChunkName: "" */))
const _3726c16f = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/list' /* webpackChunkName: "" */))
const _0cce7ce6 = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/create' /* webpackChunkName: "" */))
const _37237edb = () => interopDefault(import('../src/modules/site-settings/pages/index/user-sources/edit' /* webpackChunkName: "" */))
const _311afabb = () => interopDefault(import('../src/modules/teams/pages/frontend/create' /* webpackChunkName: "" */))
const _413ff724 = () => interopDefault(import('../src/modules/teams/pages/frontend/manage' /* webpackChunkName: "" */))
const _1d37c99a = () => interopDefault(import('../src/modules/teams/pages/frontend/index' /* webpackChunkName: "" */))
const _baf7306c = () => interopDefault(import('../src/modules/teams/pages/frontend/profile' /* webpackChunkName: "" */))
const _180c121f = () => interopDefault(import('../src/modules/updates/pages/frontend/index' /* webpackChunkName: "" */))
const _f627d7e0 = () => interopDefault(import('../src/modules/updates/pages/frontend/show' /* webpackChunkName: "" */))
const _ca5e6a70 = () => interopDefault(import('../src/modules/updates/pages/admin/index' /* webpackChunkName: "" */))
const _e8ad7ed2 = () => interopDefault(import('../src/modules/updates/pages/admin/select-loan' /* webpackChunkName: "" */))
const _95778474 = () => interopDefault(import('../src/modules/updates/pages/admin/create' /* webpackChunkName: "" */))
const _1dc2b554 = () => interopDefault(import('../src/modules/updates/pages/admin/edit' /* webpackChunkName: "" */))
const _65864e06 = () => interopDefault(import('../src/modules/users/pages/admin/index' /* webpackChunkName: "" */))
const _4b26293e = () => interopDefault(import('../src/modules/users/pages/admin/index/user-search' /* webpackChunkName: "" */))
const _18496c73 = () => interopDefault(import('../src/modules/users/pages/admin/index/re-bulk-update' /* webpackChunkName: "" */))
const _5baed8e4 = () => interopDefault(import('../src/modules/users/pages/admin/index/bulk-create' /* webpackChunkName: "" */))
const _385c19e9 = () => interopDefault(import('../src/modules/users/pages/admin/mfi-index' /* webpackChunkName: "" */))
const _34d85017 = () => interopDefault(import('../src/modules/users/pages/admin/user' /* webpackChunkName: "" */))
const _804235fc = () => interopDefault(import('../src/modules/users/pages/admin/user/edit' /* webpackChunkName: "" */))
const _2f1a3ade = () => interopDefault(import('../src/modules/users/pages/admin/user/gift-aid-history' /* webpackChunkName: "" */))
const _5c0a2e80 = () => interopDefault(import('../src/modules/users/pages/admin/user/email-change-history' /* webpackChunkName: "" */))
const _37c7e1a1 = () => interopDefault(import('../src/modules/users/pages/admin/transactions' /* webpackChunkName: "" */))
const _6605b3f1 = () => interopDefault(import('../src/modules/users/pages/admin/roles' /* webpackChunkName: "" */))
const _34d47ccf = () => interopDefault(import('../src/modules/users/pages/admin/mfis' /* webpackChunkName: "" */))
const _413f2c45 = () => interopDefault(import('../src/modules/users/pages/admin/credit' /* webpackChunkName: "" */))
const _1b99531d = () => interopDefault(import('../src/modules/users/pages/frontend/my-account' /* webpackChunkName: "" */))
const _88adc31a = () => interopDefault(import('../src/modules/users/pages/frontend/my-loans' /* webpackChunkName: "" */))
const _9dc68832 = () => interopDefault(import('../src/modules/users/pages/frontend/my-grants' /* webpackChunkName: "" */))
const _75dc9c95 = () => interopDefault(import('../src/modules/users/pages/frontend/my-vouchers' /* webpackChunkName: "" */))
const _489bd665 = () => interopDefault(import('../src/modules/users/pages/frontend/my-transactions' /* webpackChunkName: "" */))
const _11f619b6 = () => interopDefault(import('../src/modules/users/pages/frontend/add-credit' /* webpackChunkName: "" */))
const _3647ecaa = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit' /* webpackChunkName: "" */))
const _397c221e = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/index/index' /* webpackChunkName: "" */))
const _f2762a32 = () => interopDefault(import('../src/modules/users/pages/frontend/withdraw-credit/completed' /* webpackChunkName: "" */))
const _521b2738 = () => interopDefault(import('../src/modules/users/pages/frontend/profile' /* webpackChunkName: "" */))
const _784752db = () => interopDefault(import('../src/modules/users/pages/frontend/edit' /* webpackChunkName: "" */))
const _64aa6cb2 = () => interopDefault(import('../src/modules/users/pages/frontend/close-account' /* webpackChunkName: "" */))
const _1460abe0 = () => interopDefault(import('../src/modules/vouchers/pages/frontend/index' /* webpackChunkName: "" */))
const _7347756e = () => interopDefault(import('../src/modules/vouchers/pages/frontend/free' /* webpackChunkName: "" */))
const _59fb3fc4 = () => interopDefault(import('../src/modules/vouchers/pages/frontend/redeem' /* webpackChunkName: "" */))
const _1cb4cd18 = () => interopDefault(import('../src/modules/vouchers/pages/admin' /* webpackChunkName: "" */))
const _a1a2462e = () => interopDefault(import('../src/modules/vouchers/pages/admin/listing' /* webpackChunkName: "" */))
const _4c287292 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases' /* webpackChunkName: "" */))
const _6c648ff2 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/listing' /* webpackChunkName: "" */))
const _20e96719 = () => interopDefault(import('../src/modules/vouchers/pages/admin/bulk-purchases/create' /* webpackChunkName: "" */))
const _3b4e8cd5 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/completed' /* webpackChunkName: "" */))
const _8f6ba410 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/requested' /* webpackChunkName: "" */))
const _ec60a31c = () => interopDefault(import('../src/modules/withdrawals/pages/admin/batches' /* webpackChunkName: "" */))
const _74e63aa1 = () => interopDefault(import('../src/modules/withdrawals/pages/admin/pending' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/foo",
    component: _dbe5a5be,
    name: "foo"
  }, {
    path: "/ie",
    component: _e1c7fa8a,
    name: "ie"
  }, {
    path: "/ie/ie",
    component: _1b878a0a,
    name: "ie-ie"
  }, {
    path: "/admin",
    component: _250f72e6,
    name: "admin:index"
  }, {
    path: "/angels",
    component: _8a97a6ba,
    name: "angels:frontend:index"
  }, {
    path: "/angels/thank-you",
    component: _1568de0d,
    name: "angels:frontend:thank-you"
  }, {
    path: "/appeals/:appealSlug",
    component: _58da570a,
    name: "appeals:frontend:show"
  }, {
    path: "/register/:referralCode?",
    component: _62cf9cdb,
    name: "auth:register"
  }, {
    path: "/login",
    component: _fe08279e,
    name: "auth:login"
  }, {
    path: "/forgot-password",
    component: _68bc5666,
    name: "auth:forgot-password"
  }, {
    path: "/reset-password",
    component: _1648e4de,
    name: "auth:reset-password"
  }, {
    path: "/basket",
    component: _757383ef,
    children: [{
      path: "",
      component: _a92ec834,
      name: "basket:frontend:index"
    }, {
      path: "payment",
      component: _b2d0d834,
      name: "basket:frontend:payment"
    }, {
      path: "payment-details",
      component: _403e235b,
      name: "basket:frontend:payment-details"
    }, {
      path: "login",
      component: _465f6d6e,
      name: "basket:frontend:login"
    }, {
      path: "receipt/:uuid",
      component: _15fdd418,
      name: "basket:frontend:receipt"
    }]
  }, {
    path: "/info",
    component: _12d98707
  }, {
    path: "/info/:uri*",
    component: _12d98707,
    name: "cms:article"
  }, {
    path: "/news/latest",
    component: _2c99ecfe,
    name: "cms:news"
  }, {
    path: "/news/post/:id",
    component: _a6996ef8,
    name: "cms:news-item"
  }, {
    path: "/donate",
    component: _76af3f4e,
    name: "donations:frontend:donate"
  }, {
    path: "/admin/mfis/entrepreneurs",
    component: _4a8fe110,
    name: "entrepreneurs:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/create",
    component: _72458f16,
    name: "entrepreneurs:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)",
    component: _12efcab8,
    name: "entrepreneurs:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/select-mfi",
    component: _183c7a8e,
    name: "entrepreneurs:admin:select-mfi"
  }, {
    path: "/admin/grant-repayments/mfis/due",
    component: _0b2b2a0a,
    name: "grant-repayments:admin:mfis:due:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/due",
    component: _d59ed5b0,
    name: "grant-repayments:admin:mfis:due:mfi"
  }, {
    path: "/admin/grant-repayments/mfis/archive",
    component: _225921d8,
    name: "grant-repayments:admin:archive:index"
  }, {
    path: "/admin/grant-repayments/mfis/:mfiId(\\d+)/archive",
    component: _27762876,
    name: "grant-repayments:admin:archive:mfi"
  }, {
    path: "/admin/mfis/grant-reports",
    component: _a3af780a,
    name: "grantReporting:admin:current"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/:reportId(\\d+)",
    component: _68579298,
    name: "grantReporting:admin:show"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/grant-reports/no-current-report",
    component: _66ef833d,
    name: "grantReporting:admin:no-current-report"
  }, {
    path: "/admin/mfis/grant-reports/archive",
    component: _24bbd5ab,
    name: "grantReporting:admin:archive"
  }, {
    path: "/grants",
    component: _330bb8b3,
    name: "grants:frontend:home"
  }, {
    path: "/grants/search",
    component: _9d98f708,
    name: "grants:frontend:search"
  }, {
    path: "/grants/:grantId(\\d+)",
    component: _33109ed1,
    name: "grants:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/grants",
    component: _7329dc6e,
    name: "grants:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/grants/select-entrepreneur",
    component: _045608fe,
    name: "grants:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/create",
    component: _061a5236,
    name: "grants:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/grants/:grantId(\\d+)",
    component: _40330a33,
    name: "grants:admin:edit"
  }, {
    path: "/",
    component: _ad161c40,
    name: "homepage:index"
  }, {
    path: "/search",
    component: _e0b3f118,
    name: "loans:frontend:search"
  }, {
    path: "/loans/:loanId(\\d+)",
    component: _c091006e,
    name: "loans:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans",
    component: _42745e7e,
    name: "loans:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/select-entrepreneur",
    component: _561a2879,
    name: "loans:admin:select-entrepreneur"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/create",
    component: _20201226,
    name: "loans:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)",
    component: _72888c3b,
    name: "loans:admin:edit"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/messages",
    component: _2bc19efd,
    name: "loans:admin:messages:index"
  }, {
    path: "/info/about-us/lendwithcare-shop",
    component: _2017e436,
    name: "marketing:frontend:shop"
  }, {
    path: "/welcome-answer/:answer",
    component: _72ec70b8,
    name: "marketing:user-journey:answer"
  }, {
    path: "/admin/mfis",
    component: _55e69af5,
    name: "mfis:admin:index"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)",
    component: _23cbab87,
    name: "mfis:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/administrators",
    component: _58bd3941,
    name: "mfis:admin:administrators"
  }, {
    path: "/admin/mfis/mfi-info-messages",
    component: _57c4ad58,
    name: "mfis:admin:mfiInfoMessages"
  }, {
    path: "/admin/mfis/mfi-info-messages/:mfiInfoMessageId(\\d+)",
    component: _1759d548,
    name: "mfis:admin:editMfiInfoMessages"
  }, {
    path: "/admin/mfis/offset-reports",
    component: _8e9f7d1a,
    name: "offsetting:admin:current"
  }, {
    path: "/admin/mfis/offset-reports/archive",
    component: _a58b55ba,
    name: "offsetting:admin:archive"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/:offsetReportId(\\d+)",
    component: _ebcaab88,
    name: "offsetting:admin:edit"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/offset-reports/no-current-report",
    component: _29cf0cb5,
    name: "offsetting:admin:no-current-report"
  }, {
    path: "/my-account/make-referral",
    component: _5d2da23d,
    name: "referral:frontend:make-referral"
  }, {
    path: "/referral/:referralCode?",
    component: _943a4548,
    name: "referral:frontend:referral"
  }, {
    path: "/admin/repayments",
    component: _309c8c12,
    name: "repayments:admin:index"
  }, {
    path: "/admin/repayments/archive",
    component: _498317fc,
    name: "repayments:admin:archive"
  }, {
    path: "/admin/reporting",
    component: _10ae8048,
    children: [{
      path: "donations",
      component: _027c13cc,
      name: "reporting:donations"
    }, {
      path: "reconciliation",
      component: _714388ec,
      name: "reporting:reconciliation"
    }, {
      path: "user-changes",
      component: _6c509d28,
      name: "reporting:user-changes-download"
    }, {
      path: "user-data",
      component: _985d5136,
      name: "reporting:user-data-download"
    }, {
      path: "gift-aid",
      component: _ac6657de,
      name: "reporting:gift-aid-data-download"
    }, {
      path: "entrepreneur-and-loan-data",
      component: _c8d6ca38,
      name: "reporting:entrepreneur-and-loan-data-download"
    }]
  }, {
    path: "/admin/site-settings",
    component: _b04033b2,
    name: "site-settings:index",
    children: [{
      path: "/admin/site-settings/loan-activity-types",
      component: _58dfaad0,
      name: "site-settings:loan-activity-types"
    }, {
      path: "/admin/site-settings/user-sources",
      component: _68ef329e,
      children: [{
        path: "",
        component: _3726c16f,
        name: "site-settings:user-sources:list"
      }, {
        path: "create",
        component: _0cce7ce6,
        name: "site-settings:user-sources:create"
      }, {
        path: ":userSourceId(\\d+)",
        component: _37237edb,
        name: "site-settings:user-sources:edit"
      }]
    }]
  }, {
    path: "/my-account/teams/create",
    component: _311afabb,
    name: "teams:frontend:create"
  }, {
    path: "/my-account/teams/:uuid",
    component: _413ff724,
    name: "teams:frontend:manage"
  }, {
    path: "/teams",
    component: _1d37c99a,
    name: "teams:frontend:index"
  }, {
    path: "/teams/:slug",
    component: _baf7306c,
    name: "teams:frontend:profile"
  }, {
    path: "/updates",
    component: _180c121f,
    name: "updates:frontend:index"
  }, {
    path: "/updates/:updateId(\\d+)",
    component: _f627d7e0,
    name: "updates:frontend:show"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates",
    component: _ca5e6a70,
    name: "updates:admin:index"
  }, {
    path: "/admin/mfis/entrepreneurs/loans/updates/create/select-loan",
    component: _e8ad7ed2,
    name: "updates:admin:select-loan"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/create",
    component: _95778474,
    name: "updates:admin:create"
  }, {
    path: "/admin/mfis/:mfiId(\\d+)/entrepreneurs/:entrepreneurId(\\d+)/loans/:loanId(\\d+)/updates/:updateId(\\d+)",
    component: _1dc2b554,
    name: "updates:admin:edit"
  }, {
    path: "/admin/users",
    component: _65864e06,
    children: [{
      path: "",
      component: _4b26293e,
      name: "users:admin:index"
    }, {
      path: "raisers-edge",
      component: _18496c73,
      name: "users:admin:re-bulk-update"
    }, {
      path: "bulk-create",
      component: _5baed8e4,
      name: "users:admin:bulk-create"
    }]
  }, {
    path: "/admin/mfi-users",
    component: _385c19e9,
    name: "users:admin:mfi-index"
  }, {
    path: "/admin/users/:userId(\\d+)",
    component: _34d85017,
    children: [{
      path: "",
      component: _804235fc,
      name: "users:admin:edit"
    }, {
      path: "gift-aid-history",
      component: _2f1a3ade,
      name: "users:admin:gift-aid-history"
    }, {
      path: "email-change-history",
      component: _5c0a2e80,
      name: "users:admin:email-change-history"
    }]
  }, {
    path: "/admin/users/:userId(\\d+)/transactions",
    component: _37c7e1a1,
    name: "users:admin:transactions"
  }, {
    path: "/admin/users/:userId(\\d+)/roles",
    component: _6605b3f1,
    name: "users:admin:roles"
  }, {
    path: "/admin/users/:userId(\\d+)/mfis",
    component: _34d47ccf,
    name: "users:admin:mfis"
  }, {
    path: "/admin/users/:userId(\\d+)/credit",
    component: _413f2c45,
    name: "users:admin:credit"
  }, {
    path: "/my-account",
    component: _1b99531d,
    name: "users:frontend:my-account"
  }, {
    path: "/my-account/my-loans",
    component: _88adc31a,
    name: "users:frontend:my-loans"
  }, {
    path: "/my-account/my-grants",
    component: _9dc68832,
    name: "users:frontend:my-grants"
  }, {
    path: "/my-account/my-gift-vouchers",
    component: _75dc9c95,
    name: "users:frontend:my-vouchers"
  }, {
    path: "/my-account/my-transactions",
    component: _489bd665,
    name: "users:frontend:my-transactions"
  }, {
    path: "/my-account/add-credit",
    component: _11f619b6,
    name: "users:frontend:add-credit"
  }, {
    path: "/my-account/withdraw-credit",
    component: _3647ecaa,
    children: [{
      path: "",
      component: _397c221e,
      name: "users:frontend:withdraw-credit"
    }, {
      path: "completed",
      component: _f2762a32,
      name: "users:frontend:withdrawal-complete"
    }]
  }, {
    path: "/profile/:userUuid",
    component: _521b2738,
    name: "users:frontend:profile"
  }, {
    path: "/my-account/my-details",
    component: _784752db,
    name: "users:frontend:edit-details"
  }, {
    path: "/my-account/my-profile",
    component: _784752db,
    name: "users:frontend:edit-profile"
  }, {
    path: "/my-account/my-settings",
    component: _784752db,
    name: "users:frontend:edit-settings"
  }, {
    path: "/my-account/close/:token",
    component: _64aa6cb2,
    name: "users:frontend:close-account"
  }, {
    path: "/gift-vouchers",
    component: _1460abe0,
    name: "vouchers:frontend:index"
  }, {
    path: "/gift-vouchers/claim-free",
    component: _7347756e,
    name: "vouchers:frontend:free"
  }, {
    path: "/gift-vouchers/redeem",
    component: _59fb3fc4,
    name: "vouchers:frontend:redeem"
  }, {
    path: "/admin/vouchers",
    component: _1cb4cd18,
    children: [{
      path: "",
      component: _a1a2462e,
      name: "vouchers:admin:list"
    }, {
      path: "/admin/vouchers/bulk-purchases",
      component: _4c287292,
      children: [{
        path: "",
        component: _6c648ff2,
        name: "vouchers:admin:bulk-purchases"
      }, {
        path: "new",
        component: _20e96719,
        name: "vouchers:admin:create-bulk-purchase"
      }]
    }]
  }, {
    path: "/admin/withdrawals/completed",
    component: _3b4e8cd5,
    name: "withdrawals:admin:completed"
  }, {
    path: "/admin/withdrawals/requested",
    component: _8f6ba410,
    name: "withdrawals:admin:requested"
  }, {
    path: "/admin/withdrawals/batches",
    component: _ec60a31c,
    name: "withdrawals:admin:batches"
  }, {
    path: "/admin/withdrawals/pending",
    component: _74e63aa1,
    name: "withdrawals:admin:pending"
  }],

  parseQuery: function (queryString) {
      return window.qs.parse(queryString, {
        allowDots: true,
        arrayFormat: 'brackets',
        ignoreQueryPrefix: true
      });
    },
  stringifyQuery: function (queryObject) {
      return window.qs.stringify(queryObject, {
        addQueryPrefix: true,
        allowDots: true,
        arrayFormat: 'brackets',
        encode: false
      });
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
